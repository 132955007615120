import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import { DropdownList } from 'react-widgets';

// components
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';

// utils
import { inValidRole, numberToCurrency } from '../../libs/utils';

// api
import { getCompanySources, getCompanySourceReport } from '../../api/company'

const CompanySourceReport = () => {

  const [companySources, setCompanySources] = useState([]);
  const [companySource, setCompanySource] = useState(null);
  const [companySourceChildren, setCompanySourceChildren] = useState([]);
  const [companySourceChild, setCompanySourceChild] = useState(null);
  const [report, setReport] = useState([]);
  const [totals, setTotals] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCompanySources().then(data => {
      setCompanySources(data.company_sources);
    });
  }, []);


  const handleCompanySourceChange = (value) => {
    setCompanySource(value);
    const list = companySources.filter(source => source.id === value.id)[0].list;
    if (list.length > 0) {
    setCompanySourceChildren(list);
    } else {
      setCompanySourceChildren([]);
    }
    setCompanySourceChild(null);
    setReport([]);
    setTotals(null);
  }

  const handleCompanySourceChildChange = (value) => {
    setCompanySourceChild(value);
  }

  const handleGenerateReport = (e) => {
    e.preventDefault();
    console.log(companySource);
    console.log(companySourceChild);
    setLoading(true);
    getCompanySourceReport(companySource.id, companySourceChild?.id).then(data => {
      console.log(data);
      setReport(data.report);
      setTotals(data.totals);
      setLoading(false);
    });
  }

  // render

  const renderTotals = () => {
    if (totals === null) {
      return
    }

    return (
      <Row key="totals" className="mt-3 mb-3 border-bottom border-5">
        <Col md={2} className="font-bold">Total</Col>
        <Col md={2} className="text-center">
          <Alert color="success">
            <h4 className="text-success">Approved</h4>
            <strong>
              <small>Total Amount for Quotes:</small>
              <br/>
              {numberToCurrency(totals.approved_amount)}
            </strong>
            <br />
            Total Quotes: {totals.approved_count}
            <br />
            <em>Avg: {average(totals.approved_amount, totals.approved_count)}</em>
            </Alert> 
        </Col>
        <Col md={2} className="text-center">
          <Alert color="info">
            <h4>Open</h4>
            <strong>
              <small>Total Amount for Quotes:</small>
              <br/>
              {numberToCurrency(totals.open_amount)}
            </strong>
            <br />
            Total Quotes: {totals.open_count}
            <br />
            <em>Avg: {average(totals.open_amount, totals.open_count)}</em>
          </Alert>
        </Col>
        <Col md={2} className="text-center">
          <Alert color="danger">
            <h4>Expired</h4>
            <strong>
              <small>Total Amount for Quotes:</small>
              {numberToCurrency(totals.expired_amount)}
            </strong>
            <br />
            Total Quotes: {totals.expired_count}
            <br />
            <em>Avg: {average(totals.expired_amount, totals.expired_count)}</em>
          </Alert>
        </Col>
        <Col md={2} className="text-center">
          <Alert color="warning">
            <h4>Declined</h4>
            <strong>
              <small>Total Amount for Quotes:</small>
              <br/>
              {numberToCurrency(totals.declined_amount)}
            </strong>
            <br />
            Total Quotes: {totals.declined_count}
            <br />
            <em>Avg: {average(totals.declined_amount, totals.declined_count)}</em>
          </Alert>
        </Col>
        <Col md={2} className="text-center">
          <Alert color="secondary">
            <h4>Total</h4>
            <strong>
              <small>Total Amount for Quotes:</small>
              <br/>
              {numberToCurrency(totals.total_amount)}
            </strong>
            <br />
            Total Quotes: {totals.total}
            <br />
            <em>Avg: {average(totals.total_amount, totals.total)}</em>
          </Alert>
        </Col>
      </Row>
    );
  }

  const average = (total, count) => {
    if (count === 0) {
      return numberToCurrency(0);
    }

    return numberToCurrency(total / count);
  }

  const renderReport = () => {
    if (report.length === 0 && companySource !== null) {
      return <Row><Col className="text-center font-bold m-5">No data found</Col></Row>;
    }

    return report.map((item) => (
      <>
        <Row key={item.name} className="mt-3">
          <Col md={2}>{item.name}</Col>
          <Col md={2} className="text-center">
            <Alert color="success">
              <strong>{numberToCurrency(item.quotes.approved_amount)}</strong>
              <br />
              {item.quotes.approved_count}
              <br />
              <em>Avg: {average(item.quotes.approved_amount, item.quotes.approved_count)}</em>
            </Alert> 
          </Col>
          <Col md={2} className="text-center">
            <Alert color="info">
              <strong>{numberToCurrency(item.quotes.open_amount)}</strong>
              <br />
              {item.quotes.open_count}
              <br />
              <em>Avg: {average(item.quotes.open_amount, item.quotes.open_count)}</em>
            </Alert>
          </Col>
          <Col md={2} className="text-center">
            <Alert color="danger">
              <strong>{numberToCurrency(item.quotes.expired_amount)}</strong>
              <br />
              {item.quotes.expired_count}
              <br />
              <em>Avg: {average(item.quotes.expired_amount, item.quotes.expired_count)}</em>
            </Alert>
          </Col>
          <Col md={2} className="text-center">
            <Alert color="warning">
              <strong>{numberToCurrency(item.quotes.declined_amount)}</strong>
              <br />
              {item.quotes.declined_count}
              <br />
              <em>Avg: {average(item.quotes.declined_amount, item.quotes.declined_count)}</em>
            </Alert>
          </Col>
          <Col md={2} className="text-center">
            <Alert color="secondary">
              <strong>{numberToCurrency(item.quotes.total_amount)}</strong>
              <br />
              {item.quotes.total}
              <br />
              <em>Avg: {average(item.quotes.total_amount, item.quotes.total)}</em>
            </Alert>
          </Col>
        </Row>
        <hr />
      </>
    ));
  }

  return (
    <>
      <PageHeader title="Company Sources Report" />
      <CardWrapper>
        <Row>
          <Col md={5}>
            <label>Company Source</label>
            <DropdownList
              data={companySources}
              dataKey="id"
              textField="name"
              valueField="id"
              value={companySource}
              onChange={value => {
                handleCompanySourceChange(value);
              }}
            />
          </Col>
          <Col md={5}>
              <label>Company Source Options</label>
              <DropdownList
                data={companySourceChildren}
                dataKey="id"
                textField="name"
                valueField="id"
                value={companySourceChild}
                onChange={value => {
                  handleCompanySourceChildChange(value);
                }}
                disabled={companySourceChildren.length === 0}
              />
          </Col>
          <Col md={2}>
            <Button color="primary" onClick={(e) => handleGenerateReport(e)}>
              Generate Report
            </Button>
          </Col>
        </Row>
        {loading && <div>Loading...</div>}
        {renderTotals()}
        {renderReport()}
      </CardWrapper>
    </>
  )
}

export default CompanySourceReport;
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';


class DefaultErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      message: 'Error occured, has been reported.',
    };
  }

  componentDidMount() {
    if (this.props.message !== undefined) {
      this.setState({
        message: this.props.message
      });
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // honeybadger.notify(error);
  }

  render() {
    const { message } = this.state;

    if (this.state.hasError) {
      return (
        <Row>
          <Col>
            <span className="label label-danger">
              {message}
            </span>
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}

export default DefaultErrorBoundary;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';

// components
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';

// libs

import { getWebsite, updateWebsite } from '../../api/website'


const WebsiteForm = () => {
  const { id } = useParams()

  const [website, setWebsite] = useState(null);

  useEffect(() => {
    getWebsite(id).then((res) => {
      setWebsite(res);
    });
  }, [id]);

  if (!website) {
    return <div>Loading...</div>;
  }

  const handleInvalid = (e) => {
    e.preventDefault();
    updateWebsite(id, { status: 'invalid' }).then((res) => {
      window.location.href = `/website/`;
    });
  }

  const setTaskAndRedirect = (e) => {
    e.preventDefault();

    let list = [];
    list.push(`Name: ${website.data.first_name} ${website.data.last_name}`);
    list.push(`Email: ${website.data.email_address}`);
    list.push(`Phone: ${website.data.phone_number}`);
    list.push(`Company: ${website.data.company}`);
    list.push(`Address: ${website.data.street_address} ${website.data.city}, ${website.data.state} ${website.data.zip_code}`);
    list.push(`Brand: ${website.data.brand}`);
    list.push(`Inquiry Type: ${website.data.inquiry_type}`);
    list.push(`Comment: ${website.data.comment}`);

    let description = list.join("\n");

    const data = {
      name: "Comment from Website",
      description: description
    }

    sessionStorage.setItem('website:task', JSON.stringify(data));

    updateWebsite(id, { status: 'complete' }).then((res) => {
      window.location.href = "/tasks/new";
    });
  }

  return (
    <>
      <PageHeader title={`Website ${website.source.toUpperCase()}`} />
      <CardWrapper>
        <Row>
          <Col md={6}>
            <h3>Information</h3>
            <Row>
              <Col md={6}>Name</Col>
              <Col md={6}>{website.data.first_name} {website.data.last_name}</Col>
            </Row>
            <Row>
              <Col md={6}>Company</Col>
              <Col md={6}>{website.data.company}</Col>
            </Row>
            <Row>
              <Col md={6}>Email</Col>
              <Col md={6}>{website.data.email_address}</Col>
            </Row>
            <Row>
              <Col md={6}>Phone</Col>
              <Col md={6}>{website.data.phone_number}</Col>
            </Row>
          </Col>
          <Col md={6}>
            <h3>&nbsp;</h3>
            <Row>
              <Col md={6}>Address</Col>
              <Col md={6}>
                {website.data.street_address}
                <br />
                {website.data.city}, {website.data.state} {website.data.zip_code} {website.data.country}
              </Col>
            </Row>
          </Col>
        </Row>

        {website.source === "comment" && (
          <>
            <Row className="mt-3">
              <Col>
                <h3>Comment</h3>
                <Row>
                  <Col md={6}>
                    <h5>Inquiry Type</h5>
                    <p>{website.data.inquiry_type}</p>
                    <h5>Brand</h5>
                    <p>{website.data.brand}</p>
                  </Col>
                  <Col md={6}>
                    <h5>Comment</h5>
                    <p>{website.data.comment}</p>
                  </Col>
                </Row>
              </Col>
            </Row>

            {website.status === "pending" && (
              <Row className="mt-4">
                <Col md={6} className="text-center">
                  <button className="btn btn-danger" onClick={handleInvalid}>
                    Invalid, ignore and destroy!
                  </button>
                </Col>
                <Col md={6} className="text-center">
                  <button
                    onClick={(e) => setTaskAndRedirect(e)}
                    className="btn btn-primary"                  
                  >
                    Let's Turn this into a Task!
                  </button>

                  <br />
                  <br />
                  <em class="mt-4">Once you click the button above, this item will be marked as complete and you will be brought to the task creation screen.</em>
                </Col>
              </Row>
            )}
          </>
        )}

        {website.source === "quote" && (
          <>
            <Row className="mt-3">
              <Col>
                <h3>Requested Items</h3>
              </Col>
            </Row>    
    
            {website.data.quote_items && (
              <>
                {website.data.quote_items.map((item) => (
                  <Row key={item.id}>
                    <Col md={4}>{item.sku}</Col>
                    <Col md={4}>{item.name}</Col>
                    <Col md={4}>{item.quantity}</Col>
                  </Row>
                ))}
              </>
            )}

            {website.status === "pending" && (
            <Row className="mt-4">
              <Col md={6} className="text-center">
                <button className="btn btn-danger" onClick={handleInvalid}>
                  Invalid, ignore and destroy!
                </button>
              </Col>
              <Col md={6} className="text-center">
                <Link
                  to={`/website/${id}/quote`}
                  className="btn btn-primary"                  
                >
                  Let's Turn this into a QUOTE!
                </Link>
              </Col>
            </Row>
            )}

          </>
        )}

        {website.status !== "pending" && (
          <Row>
            <Col md={12}>
              <Link to="/website" className="btn btn-primary">Back to List</Link>
            </Col>
          </Row>
        )}
        
      </CardWrapper>
    </>
  )
}

export default WebsiteForm;
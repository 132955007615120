import React from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';


import { checkForAbility } from '../../libs/utils';
// import TaskForm from './TaskForm';
// import TaskList from './TaskList';
import WebsiteList from './List';
import WebsiteForm from './Form';
import WebsiteQuote from './Quote';
import WebsiteTask from './Task';

const Website = () => {

  if (!checkForAbility('website_feed', localStorage)) {
    window.location.href = "/"
  }

  return (
    <>
      <Helmet>
        <title>The Hub - Website</title>
      </Helmet>
      <Routes>
        <Route path=":id/task" element={<WebsiteTask />} />
        <Route path=":id/quote" element={<WebsiteQuote />} />
        <Route path=":id" element={<WebsiteForm />} />
        <Route path="" element={<WebsiteList />} />
      </Routes>
    </>
  );
}

export default Website;

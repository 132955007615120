import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import TimelineIcon from 'mdi-react/TimelineIcon';
import BarChartIcon from 'mdi-react/BarChartIcon';

import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';
import { inValidRole } from '../../libs/utils';

export default class ReportList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <PageHeader title="Reporting" />,
        <Row>
          <Col md={4}>
            <CardWrapper>
              <div className="text-center">
                <Link to="/reports/timeline">
                  <TimelineIcon size={48} />
                  <br />
                  Weekly Timeline
                </Link>
              </div>
            </CardWrapper>
          </Col>
          {(inValidRole(['admin', 'sales_manager', 'csr_manager'], localStorage)) &&
            <Col md={4}>
              <CardWrapper>
                <div className="text-center">
                  <Link to="/reports/activity_log">
                    <TimelineIcon size={48} />
                    <br />
                    Activity
                    </Link>
                </div>
              </CardWrapper>
            </Col>
          }
          <Col md={4}>
            <CardWrapper>
              <div class="text-center">
                <Link to="/quote/report">
                  <BarChartIcon size={48} />
                  <br />
                  Quote Report
                </Link>
              </div>
            </CardWrapper>
          </Col>
          <Col md={4}>
            <CardWrapper>
              <div class="text-center">
                <Link to="/reports/company_sources">
                  <BarChartIcon size={48} />
                  <br />
                  Company Sources Report
                </Link>
              </div>
            </CardWrapper>
          </Col>
        </Row>
      </>
    );
  }
}
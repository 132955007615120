import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Moment from 'react-moment';

import WrenchIcon from 'mdi-react/WrenchIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

export default function User({ user }) {
  const renderSpecialAbilities = (abilities) => {
    if (abilities.length === 0) {
      return null;
    }
    let list = [];
    
    Object.entries(abilities).forEach(([key, value]) => {
      if (value) {
        if (key === 'root') {
          list.push("Admin Special Access")
        } else if (key === 'check') {
          list.push("Check Processing")
        } else if (key === 'ecommerce') {
          list.push("Ecommerce") 
        } else if (key === 'quotes_no_cc_fee') {
          list.push("Quotes -> No Credit Card Fee")
        } else if (key === 'website_feed') {
          list.push("Website Feed Processing")
        } else {
          list.push(key);
        }
      }
    });
    
    if (list.length === 0) {
      return null;
    }

    return (
      <div>
        <small>
          <strong>Special Abilities:</strong>
          {' '}
          {list.join(', ').toUpperCase()}
        </small>
      </div>
    );
  }

  return (
    <Row className="mb-4 pt-2 pb-2 border border-top-0 border-left-0 border-right-0">
      <Col md={2}>
        <img src={user.avatar} alt={user.name} className="img-circle" width={60} />
      </Col>
      <Col md={4}>
        <strong>
          {user.name}
        </strong>
        <br />
        {user.email}
      </Col>
      <Col md={4}>
        {user.role_name}
        {renderSpecialAbilities(user.special_abilities)}
      </Col>
      <Col md={2}>
        <Link
          to={{
            pathname: `/admin/users/${user.id}`,
          }}
        >
          <WrenchIcon size={24} />
        </Link>
      </Col>
    </Row>
  );
}


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Pagination from 'react-js-pagination';
import Moment from 'react-moment';

// components
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';

// libs

import { getWebsiteList } from '../../api/website'

const defaultFilters = {
  paging: {
    page: 1,
    totalRecords: 0
  },
  filters: {
    status: 'pending'
  }
}

const statusOptions = [
  { value: 'pending', name: 'Pending' },
  { value: 'complete', name: 'Complete' },
  { value: 'invalid', name: 'Invalid' }
]

const useFilterStorage = (storageKey) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(storageKey)) ?? defaultFilters
  )

  React.useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return [value, setValue]
}

const WebsiteList = () => {
  const [websiteFilters, setWebsiteFilters] = useFilterStorage('website:filters')
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);

  useEffect(() => {
    loadData()
  }, [websiteFilters])

  const loadData = () => {
    setLoading(true)
    const filters = {
      currentPage: websiteFilters.paging.page,
      status: websiteFilters.filters.status
    }
    getWebsiteList(filters).then((res) => {
      setWebsites(res.websites)
      // setWebsiteFilters({
      //   ...websiteFilters,
      //   paging: {
      //     ...websiteFilters.paging,
      //     totalRecords: res.totalRecords
      //   }
      // })
      setLoading(false)
    })
  }

  const renderWebsites = () => {
    try {
      return (
        <>
          {websites.map((website) => (
            <>
              <Row key={website.id}>
                <Col md={2}><strong>{website.source.toUpperCase()}</strong></Col>
                <Col md={2}>
                  <Moment format="MM/DD/YYYY HH:mm:ss">
                    {website.created_at}
                  </Moment>
                </Col>
                <Col md={2}>{website.data.first_name} {website.data.last_name}</Col>
                <Col md={2}>{website.data.email_address}<br />{website.data.phone_number}</Col>
                <Col md={2}>{website.status}</Col>
                <Col md={2}>
                  <Link to={`/website/${website.id}`} className="btn btn-primary">View</Link>
                </Col>
              </Row>
              <hr />
            </>
          ))}
        </>
      )
    } catch (error) {
      console.error(error)
      return <div>Error loading websites</div>
    }
  }

  const renderNoRecords = () => {
    if (websites.length === 0) {
      return <Row><Col md={12} className="text-center">No records found</Col></Row>
    }
    return null;
  }

  const renderHeader = () => {
    return (
      <>
        <Row>
          <Col md={2}><strong>Type</strong></Col>
          <Col md={2}><strong>Date</strong></Col>
          <Col md={2}><strong>Name</strong></Col>
          <Col md={2}><strong>Email/Phone</strong></Col>
          <Col md={2}><strong>Status</strong></Col>
          <Col md={2}><strong>Actions</strong></Col>
        </Row>
        <hr />
      </>
    )
  }

  const renderFilter = () => {
    return (
      <>
        <Row className="mb-4">
          <Col md={1}><strong>Filter By:</strong></Col>
          <Col md={2}>
            <DropdownList
              textField="name"
              valueField="value"
              data={statusOptions}
              value={websiteFilters.filters.status}
              onChange={(value) => setWebsiteFilters({ ...websiteFilters, filters: { ...websiteFilters.filters, status: value.value } })}
            />
          </Col>
        </Row>
        <hr />
      </>
    )
  }

  return (
    <>
      <PageHeader title="Quotes & Comments from Website" />
      <CardWrapper>
        {loading && <div>Loading...</div>}

        {renderFilter()}

        {renderHeader()}
        {renderNoRecords()}
        {renderWebsites()}
      </CardWrapper>
    </>
  )
}


export default WebsiteList;
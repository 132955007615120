import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { NotificationContainer, NotificationManager } from 'react-notifications';

import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Dashboard from './Dashboard';
import ExpandedDashboard from './expandedDashboard';
import CSRDashboard from './expandedDashboard/CSRDashboard';
import Task from './tasks';
import Company from './companies';
import Profile from './profile';
import Admin from './admin';
import Event from './events';
import Reports from './reports';
import Alerts from './alerts';
import QuoteRoutes from './quote';
import Website from './website';
// import Vendor from './vendor';
import CallLog from './callLog';
import Map from './map';

import api from '../api/init';

// import 'react-notifications/lib/notifications.css';
import '../styles/Main.scss';

import CalendarView from './tasks/CalendarView';

import packageData from '../../package.json'

class Entry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      alerts: [],
    };

    this.updateSidebar = this.updateSidebar.bind(this);
  }

  componentDidMount() {
    this.loadAlerts();
    this.renderAlerts();
    api
      .get(`/api/v1/users/${localStorage.getItem('uid')}`, { withCredentials: true })
      .then((response) => {
        this.setState({ user: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderAlerts = () => {
    setInterval(() => {
      this.setState({ alerts: [] });
      this.loadAlerts();
    }, 60000)
  }

  loadAlerts() {
    api
      .get('/api/v1/alerts', { withCredentials: true })
      .then((response) => {
        if (response.data.alerts.length > 0) {
          this.setState({ alerts: response.data.alerts });
          response.data.map(alert => {
            if (!alert.seen) {
              // NotificationManager.info(alert.message, 'Go to Tasks', 7500, () => {
              //   this.props.history.push(alert.link);
              // });
            }
          });
        }
        // if (packageData.version !== response.data.version) {
        //   console.log(packageData.version, response.data.version)
        //   console.log('New version available');
        //   if (window.confirm(`You are on version ${packageData.version}. \n\nA new version of the Hub is available. Would you like to update? (Version ${response.data.version})`)) {
        //     window.location.reload();
        //   }
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateSidebar(event) {
    event.preventDefault();
    let body = document.getElementsByTagName('body')[0];
    let theHubName = document.getElementsByClassName('the-hub-name')[0];
    if (body.className.indexOf('mini-sidebar') === -1) {
      body.className = 'mini-sidebar';
      theHubName.style.display = 'none';
    } else {
      body.className = ''; //body.className.split(' ').splice(0, 3).join(' ');
      theHubName.style.display = 'inline-block';
    }
  }

  renderAdmin() {
    const { user } = this.state;
    const roleList = ['admin', 'sales_manager', 'csr_manager', 'sales_admin'];
    if (user && roleList.indexOf(user.role) > -1) {
      return (
        [
          <Route path="/admin/*" element={<Admin user={user} />} />,          
        ]
      );
    }
    return null;
  }

  renderDashboard() {
    const { user } = this.state;
    const roleList = ['sales_rep', 'sales_manager', 'admin', 'sales_admin'];
    if (user && roleList.indexOf(user.role) > -1) {
      return (
        <Route path="/dashboard" element={<ExpandedDashboard />} />
      )
    }
    if (user && ['csr', 'csr_manager'].indexOf(user.role) > -1) {
      return (
        <Route path="/dashboard" element={<CSRDashboard />} />
      )
    }
    return (
      <Route path="/dashboard" element={<Dashboard />} />
    )
  }

  render() {
    const { user, alerts } = this.state;
    return (
      <>
        <div id="main-wrapper">
          <Header {...this.props} user={user} updateSidebar={this.updateSidebar} alerts={alerts} hasNew={alerts.length > 0} key="header" />
          <aside className="left-sidebar">
            <Navigation {...this.props} user={user} />
          </aside>
          <div className="page-wrapper">
            <div className="container-fluid">
              <Routes>
                {this.renderDashboard()}
                {/* <Route path="/dashboard-beta" component={<ExpandedDashboard />} /> */}
                <Route path="/quote/*" element={<QuoteRoutes />} />
                <Route path="/call-log" element={<CallLog />} />
                <Route path="/tasks/*" element={<Task />} />
                <Route path="/calendar" element={<CalendarView />} />
                <Route path="/events/*" element={<Event />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/companies/*" element={<Company />} />
                <Route path="/website/*" element={<Website />} />
                {/* <Route path="/vendors" element={<Vendor />} /> */}
                <Route path="/profile" element={<Profile />} />
                <Route path="/reports/*" element={<Reports />} />
                {this.renderAdmin()}
                <Route path="/map/*" element={<Map />} />
              </Routes>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Entry;

// <NotificationContainer />
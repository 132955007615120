import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';


// components
import CardWrapper from '../../components/CardWrapper';
import PageHeader from '../../components/PageHeader';

// api

const WebsiteTask = () => {
  return (
    <>
      <PageHeader title="Website Comment to Task" />
      <CardWrapper>
        <div>Website Task</div>

      </CardWrapper>
    </>
  );
};

export default WebsiteTask;
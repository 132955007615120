import React, { useState, useEffect } from 'react'
import { Row, Col, Alert, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { DropdownList } from 'react-widgets'

// components

// api
import { saveContact } from '../../api/company';

// utils
import { validateEmail } from '../../libs/utils'

// constants

const Contacts = ({ contacts, quoteContacts, update, companyId, updateCompanyContactList }) => {
  const [counter, setCounter] = useState(0)
  const [show, setShow] = useState(false)
  const [errors, setErrors] = useState([])
  const [errorsContact, setErrorsContact] = useState([])
  const [contact, setContact] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telephone: '',
    telephone_ext: '',
    title: '',
  })
  const [selectedContacts, setSelectedContacts] = useState([])
  const [showAddContact, setShowAddContact] = useState(false)
  
  const permissions  = [
    { name: "View Only", value: "view_only" },
    { name: "Approval Ability", value: "approval_ability" },
  ]

  // data

  useEffect(() => {
    setSelectedContacts(quoteContacts)
  }, [quoteContacts])

  useEffect(() => {
    if (!showAddContact) {
      resetContact()
      setErrorsContact([])
    }
  }, [showAddContact])

  const makeContact = (contact) => {
    return {
      contactId: contact.id,
      name: contact.first_name + " " + contact.last_name,
      email: contact.email,
      permission: permissions[0]
    }
  }

  const saveNewContact = (e) => {
    e.preventDefault()
    let list = []
    if (contact.first_name.trim() === '') {
        list.push('Contact First Name is required')
      }
      if (contact.last_name.trim() === '') {
        list.push('Contact Last Name is required')
      }
      if (contact.title.trim() === '') {
        list.push('Contact Title is required')
      }
      if (contact.email.trim() === '') {
        list.push('Contact Email is required')
      }
      if (!validateEmail(contact.email)) {
        list.push('Contact Email is invalid')
      }
      if (contact.telephone.trim() === '') {
        list.push('Contact Telephone is required')
      }

      if (list.length > 0) {
        setErrorsContact(list)
        return false
      } else {
        let data = {
          contact: { ...contact },
        }
        saveContact(null, companyId, true, data)
          .then(res => {
            if (res.errors && res.errors.length > 0) {
              setErrorsContact(res.errors)
            } else {
              resetContact()
              setErrorsContact([])
              updateCompanyContactList()
              toggleAddContact()
            }
          })
        
        
      }
  }

  const resetContact = () => {
    setContact({
      first_name: '',
      last_name: '',
      email: '',
      telephone: '',
      telephone_ext: '',
      title: '',
    })
    setErrorsContact([])
  }

  // actions
  const toggle = () => {
    setShow(!show)
  }

  const toggleAddContact = (e) => {
    setShowAddContact(!showAddContact)
  }

  const updateContacts = () => {
    if (validateContacts()) {
      update(selectedContacts)
      toggle()
    }
  }

  const validateContacts = () => {
    let err = []
    if (selectedContacts.length === 0) {
      err.push("Please select at least one contact")
    }
    if (selectedContacts.filter((c) => c.permission.value === "approval_ability").length === 0) {
      err.push("Please select at least one contact with approval ability")
    }

    setErrors(err)
    return err.length === 0
  }

  const handleContactSelected = (contact) => {
    const data = makeContact(contact)
    if (selectedContacts.filter((c) => c.contactId === data.contactId).length > 0) {
      // console.log("remove", data)
      setSelectedContacts(selectedContacts.filter((c) => c.contactId !== data.contactId))
    } else {
      // console.log("add", data)
      let list = selectedContacts
      list.push(data)
      setSelectedContacts(list)
    }
    setCounter(counter + 1)
  }

  const handlePermissionChange = (contact, value) => {
    let data = makeContact(contact)
    data.permission = value
    let list = selectedContacts.filter((c) => c.contactId !== data.contactId)
    list.push(data)
    setSelectedContacts(list)
  }

  const isContactSelected = (contact) => {
    if (selectedContacts === undefined) {
      // console.log("selectedContacts is undefined")
      return false
    }
    // console.log("selectedContacts ->", selectedContacts.filter((c) => c.contactId === contact.id).length === 1)
    return selectedContacts.filter((c) => c.contactId === contact.id).length === 1
  }

  const handleContactInputChange = (event, field) => {
    setContact({ ...contact, [event.target.name]: event.target.value })
  }

  // render
  const renderButton = () => {
    return (
      <Button
        onClick={toggle}
        color="primary"
        title="Assign Contacts to this Quote"
      >
        Assign Contacts
      </Button>
    )
  }

  const renderContacts = () => {
    return contacts.sort((a, b) => a.first_name.localeCompare(b.first_name)).map((contact) => (
      <Row key={contact.id} className={"border-bottom py-2" + (isContactSelected(contact) ? " bg-light" : "")}>
        <Col md={3}>
          <Input
            type="checkbox"
            checked={isContactSelected(contact)}
            onChange={() => handleContactSelected(contact)}
          />
          {' '}
          <label className="font-bold" onClick={() => handleContactSelected(contact)}>
            {contact.first_name} {contact.last_name}
          </label>
        </Col>
        <Col md={3} className="small">{contact.title}</Col>
        <Col md={4} className="small">{contact.email}</Col>
        <Col md={2}>
          <DropdownList
            data={permissions}
            textField="name"
            valueField="value"
            disabled={!isContactSelected(contact)}
            onChange={(value) => handlePermissionChange(contact, value)}
            value={selectedContacts.filter((c) => c.contactId === contact.id)[0]?.permission}
          />
        </Col>
      </Row>
    ))
  }

  const renderContactForm = () => {
    return (
      <>
        <Modal color="secondary" className="mt-4" isOpen={showAddContact} onHide={toggleAddContact}>
          <ModalHeader>Add New Contact</ModalHeader>
          <ModalBody>
            <Row>
                <Col md={12}>
                  <h3>Add New Contact</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label forHtml="first_name" className="control-label">First Name *</label>
                  <input
                    type="text"
                    required
                    name="first_name"
                    id="first_name"
                    className="form-control"
                    value={contact.first_name}
                    onChange={e => handleContactInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label forHtml="last_name" className="control-label">Last Name *</label>
                  <input
                    type="text"
                    required
                    name="last_name"
                    id="last_name"
                    className="form-control"
                    value={contact.last_name}
                    onChange={e => handleContactInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label forHtml="title" className="control-label">Title *</label>
                  <input
                    type="text"
                    required
                    name="title"
                    id="title"
                    className="form-control"
                    value={contact.title}
                    onChange={e => handleContactInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label forHtml="email" className="control-label">Email Address *</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="form-control"
                    value={contact.email}
                    maxLength={100}
                    required
                    onChange={e => handleContactInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label forHtml="telephone" className="control-label">Telephone Number *</label>
                  <input
                    type="tel"
                    name="telephone"
                    id="telephone"
                    className="form-control"
                    maxLength={30}
                    required
                    value={contact.telephone} onChange={e => handleContactInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="form-group">
                  <label forHtml="telephone_ext" className="control-label">Telephone Ext</label>
                  <input
                    type="text"
                    name="telephone_ext"
                    id="telephone_ext"
                    className="form-control"
                    maxLength={10}
                    value={contact.telephone_ext}
                    onChange={e => handleContactInputChange(e)}
                  />
                </div>
              </Col>
            </Row>
            {errorsContact.length > 0 && (
              <Row>
                <Col md={12}>
                  <Alert color="danger">
                    <h3>Please fix the following errors:</h3>
                    {errorsContact.map(err => (
                      <p key={err}>{err}</p>
                    ))}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12} className="text-end">
                <Button color="secondary" onClick={toggleAddContact}>Close</Button>
                {' '}
                <Button color="primary" onClick={saveNewContact}>Save Contact</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    )
  }

  const renderInfo = () => {
    return (
      <Alert color="info" className="font-bold">
        
        At least one contact with approval ability is required.
      </Alert>
    )
  }

  const renderModal = () => {
    return (
      <Modal isOpen={show} onHide={toggle} size="xl">
        <ModalHeader>
          Contacts
        </ModalHeader>
        <ModalBody>
          {renderInfo()}
          {renderContacts()}
          {renderContactForm()}
        </ModalBody>
        <ModalFooter>
          {errors.length > 0 && (
            <Alert color="danger">
              {errors.map(err => (
                <div key={err} className="text-danger">{err}</div>
              ))}
            </Alert>
          )}
          <Button color="secondary" onClick={toggleAddContact}>Add Contact</Button>
          <Button color="primary" onClick={updateContacts}>Update & Close</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <>
      {renderButton()}
      {renderModal()}
    </>
  )
}

export default Contacts
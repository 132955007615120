import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import honeybadger from './honeybadger';

const root = createRoot(document.getElementById('root'));

root.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </HoneybadgerErrorBoundary>
)

reportWebVitals();

import React from 'react';

export function renderAvatar(user, size) {
  if (user === undefined) { return null; }
  if (user === null || user.avatar === null) { return null; }
  const { initials, avatar, id } = user;
  return (
    <img src={avatar} alt={initials} className="img-circle" width={size} key={`avatar-${id}`} />
  );
}

export function numberToCurrency(number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(number)
}

export function validAS400Id(id) {
  if (id === undefined) { return false; }
  if (id === null) { return false; }
  if (id <= 0) { return false; }
  return true;
}

export function inValidRole(roles, localStorage) {
  const role = localStorage.getItem('role');
  return roles.indexOf(role) > -1;
}

export function currentId(localStorage) {
  return localStorage.getItem('uid');
}

export function currentRole(localStorage) {
  return localStorage.getItem('role');
}

export function checkForAbility(ability, localStorage) {
  try {
    return localStorage.getItem('a').indexOf(ability) > -1;
  } catch (error) {
    return false;
  }
}

export function priorityColor(priority) {
  let color = '';
  switch (priority) {
    case 'urgent':
      color = 'danger';
      break;
    case 'high':
      color = 'warning';
      break;
    case 'low':
      color = 'info';
      break;
    case 'event':
      color = 'primary';
      break;
    default:
      color = '';
      break;
  }
  return color;
}

export function paymentOptionsList(company) {
  let list = [
    { value: 'creditCard', label: 'Credit Card' },
    { value: 'check', label: 'Check' },
    { value: 'ach', label: 'ACH' },
  ];
  if (company === undefined) { return list; }
  if (company === null) { return list; }
  if (company.terms !== undefined && company.terms !== null && company.terms !== '') {
    // list.push({ value: 'terms', label: 'Payment Terms on File' });
    list.push({ value: 'terms', label: `${company.terms} (Terms on File)` });
  }
  return list;
}

export function paymentOptionsList2(terms) {
  let list = [
    { value: 'creditCard', label: 'Credit Card' },
    { value: 'check', label: 'Check' },
    { value: 'ach', label: 'ACH' },
  ];

  if (terms !== undefined && terms !== null && terms !== '') {
    // list.push({ value: 'terms', label: 'Payment Terms on File' });
    list.push({ value: 'terms', label: `${terms} (Terms on File)` });
  }
  return list;
}

export function splitLines(text) {
  if (text === undefined) { return ""; }
  if (text === null) { return ""; }
  
  return text.split("\n").map(p => (<p>{p}</p>))
}

export function stateList() {
  return [
    { name: ' ', abbreviation: ' ' },
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'GUAM', abbreviation: 'GU' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    // { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'PUERTO RICO', abbreviation: 'PR' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' },
  ];
}

export function activityTypes() {
  return [
    { name: 'Face to Face', value: 'face_to_face' },
    { name: 'Phone Call', value: 'phone' },
    { name: 'Video Call', value: 'video_call' },
    { name: 'Text', value: 'text' },
    { name: 'Email', value: 'email' },
    { name: 'Product Issues', value: 'product_issues' },
    { name: 'AEI Responsibility', value: 'aei' },
    { name: 'Complaints/Resolutions', value: 'complaints_resolutions' },
    { name: 'Training', value: 'training' },
    { name: 'Misc.', value: 'misc' },
  ];
}

export function whenInWeeks(numberOfWeeks) {
  let list = [];
  for(let i = 1; i < numberOfWeeks; i++) {
    const text = i === 1 ? 'Week' : 'Weeks';
    list.push({ text: `${i} ${text}`, value: i });
  }
  return list;
};


export function removeDuplicates(arr) {
  let unique = arr.reduce(function (acc, curr) {
    if (!acc.includes(curr))
      acc.push(curr);
    return acc;
  }, []);
  return unique;
}

export function willCallAddress() {
  return {
    id: "willCall",
    name: "AEI Corporation (Will Call)",
    attn: null,
    address_type: "Will Call",
    street_address: "2641 DuBridge Avenue",
    street_address_2: "",
    city: "Irvine",
    state: "CA",
    zip_code: "92606",
    country: null,
    telephone: "949-474-3070"
  }
}

export function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}
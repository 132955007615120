import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Alert
} from 'reactstrap';
import { DropdownList } from 'react-widgets';

// components
import Error from '../Error'
import { stars } from '../helpers';
import CompanySourceDropdown from '../../components/companySource';

// api
import { saveCompany, saveContact } from '../../api/company';

// utils
import { inValidRole } from '../../libs/utils';

const CompanyContactModal = ({ id, buttonText, buttonTitle, showCompanyForm, showContactForm, update, companyId, extraData })  => {
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [company, setCompany] = useState({
    name: '',
    email: '',
    website: '',
    telephone: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    salesRepId: '',
    as400_id: '',
    contacts: [],
    stars: -1,
    company_source: {},
  })
  const [contact, setContact] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telephone: '',
    telephone_ext: '',
    title: '',
  })

  useEffect(() => {
    if (!isOpen) {
      resetCompany()
      resetContact()
    }
    processExtraData()
  }, [isOpen])

  const modalTitle = useMemo(() => {
    if (showCompanyForm && showContactForm) { return 'Add Company & Contact'; }
    if (showContactForm) { return 'Add Contact'; }
    return 'Add Company';
  }, [showCompanyForm, showContactForm])

  const processExtraData = () => {
    console.log(extraData)
    if (extraData) {
      if (showCompanyForm) {
        console.log("extraData - company")
        setCompany({
          name: extraData.company,
          email: extraData.email_address,
          website: '',
          telephone: extraData.phone_number,
          address: extraData.street_address,
          city: extraData.city,
          state: extraData.state,
          zip_code: extraData.zip_code,
          salesRepId: '',
          as400_id: '',
          contacts: [],
          stars: -1,
        })
      }
      
      if (showContactForm) {
        console.log("extraData - contact")
        setContact({
          first_name: extraData.first_name,
          last_name: extraData.last_name,
          email: extraData.email_address,
          telephone: extraData.phone_number,
          title: "",
        })
      }
    }
  }

  // actions
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  // update fields
  const updateField = (key, value) => {
    setCompany({...company, [key]: value})
  }

  const updateContactField = (key, value) => {
    setContact({...contact, [key]: value})
  }

  // update stars
  const updateStars = (value) => {
    updateField('stars', value.value)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateField(name, value)
  }

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    updateContactField(name, value)
  }

  const updateSource = (key, value) => {
    updateField('company_source', value)
  }

  // validate
  const validate = () => {
    let list = []
    if (showCompanyForm) {
      if (company.name === '') {
        list.push('Company Name is required')
      }
      if (company.email === '') {
        list.push('Company Email is required')
      }
      if (company.telephone === '') {
        list.push('Company Telephone is required')
      }
      if (company.address === '') {
        list.push('Company Address is required')
      }
      if (company.city === '') {
        list.push('Company City is required')
      }
      if (company.state === '') {
        list.push('Company State is required')
      }
      if (company.zip_code === '') {
        list.push('Company Zip Code is required')
      }
      if (company.company_source === undefined || company.company_source.id === '') {
        list.push('Source is required')
      } else if (company.company_source.id === 'other' && company.company_source.name === '') {
        list.push('Source is required (Other)')
      }
    }

    if (showContactForm) {
      if (contact.first_name === '') {
        list.push('Contact First Name is required')
      }
      if (contact.last_name === '') {
        list.push('Contact Last Name is required')
      }
      if (contact.title === '') {
        list.push('Contact Title is required')
      }
      if (contact.email === '') {
        list.push('Contact Email is required')
      }
      if (contact.telephone === '') {
        list.push('Contact Telephone is required')
      }
    }

    setErrors(list)
    if (list.length > 0) {
      return false
    }
    return true
  }

  // save the company
  const companyData = () => {
    let d = company
    d.source = company.company_source.name
    d.source_id = company.company_source.id
    return d
  }

  const save = (e) => {
    e.preventDefault()
    setErrors([])
    if (validate()) {

      if (showCompanyForm) {
        let data = {
          company: { ...companyData() },
        }
        saveCompany(data)
          .then(res => {
            if (res.errors && res.errors.length > 0) {
              setErrors(res.errors)
            } else {
              update(res)
              toggle()
              resetCompany()
            }
          })
      }
      if (showContactForm && companyId !== null) {
        let data = {
          contact: { ...contact },
        }
        saveContact(null, companyId, true, data)
          .then(res => {
            if (res.errors && res.errors.length > 0) {
              setErrors(res.errors)
            } else {
              update(res)
              toggle()
              resetContact()
            }
          })
      }
      document.getElementById(`${id}`)[0].reset()
    }    
  }

  // reset
  const resetCompany = () => {
    setCompany({
      name: '',
      email: '',
      website: '',
      telephone: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      salesRepId: '',
      as400_id: '',
      contacts: [],
      stars: -1,
    })
    setErrors([])
  }
  
  const resetContact = () => {
    setContact({
      first_name: '',
      last_name: '',
      email: '',
      telephone: '',
      telephone_ext: '',
      title: '',
    })
    setErrors([])
  }

  // renders
  const renderButton = () => {
    return (
      <Button
        color="primary"
        onClick={toggle}
        title={buttonTitle}
      >
        {buttonText}
      </Button>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
      >
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Alert
            color="info">
            All fields with marked * are required.
          </Alert>
          <Error errors={errors} />
          <form
            onSubmit={(e) => save(e)}
            id={id}
          >
            {showCompanyForm && (
              <>
                <Row>
                  <Col md={12} lg={7}>
                    <div className="form-group">
                      <label forHtml="name" className="control-label">Name *</label>
                      <input 
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required
                        onChange={(e) => handleInputChange(e)}
                        value={company.name}
                      />
                    </div>
                  </Col>
                  <Col md={12} lg={5}>
                    <CompanySourceDropdown
                      updateCompanySourceField={(value) => updateSource('company_source', value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={5} md={12}>
                    <div className="form-group">
                      <label forHtml="stars" className="control-label">Star Rating</label>
                      <DropdownList
                        data={stars()}
                        textField="name"
                        valueField="value"
                        onChange={value => updateStars(value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12}>
                    <div className="form-group">
                      <label forHtml="email" className="control-label">Email Address *</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        onChange={(e) => handleInputChange(e)}
                        value={company.email}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group">
                      <label forHtml="telephone" className="control-label">Telephone Number *</label>
                      <input
                        type="tel"
                        name="telephone"
                        id="telephone"
                        className="form-control"
                        onChange={(e) => handleInputChange(e)}
                        required
                        value={company.telephone}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={12}>
                    <div className="form-group">
                      <label forHtml="website" className="control-label">Website</label>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="form-control"
                        onChange={(e) => handleInputChange(e)}
                        value={company.website}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>Address</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label forHtml="address" className="control-label">Address *</label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        className="form-control"
                        required
                        onChange={(e) => handleInputChange(e)}
                        value={company.address}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={12}>
                    <div className="form-group">
                      <label forHtml="city" className="control-label">City *</label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        className="form-control"
                        required
                        onChange={(e) => handleInputChange(e)}
                        value={company.city}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group">
                      <label forHtml="state" className="control-label">State *</label>
                      <input
                        type="text"
                        name="state"
                        id="state"
                        className="form-control"
                        maxlength={2}
                        required
                        onChange={(e) => handleInputChange(e)}
                        value={company.state}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="form-group">
                      <label forHtml="zip_code" className="control-label">Zip Code *</label>
                      <input
                        type="text"
                        name="zip_code"
                        id="zip_code"
                        className="form-control"
                        required
                        onChange={(e) => handleInputChange(e)}
                        value={company.zip_code}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {showContactForm && (
              <>
                <Row>
                  <Col>
                      <h3>Contact</h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="form-group">
                      <label forHtml="first_name" className="control-label">First Name *</label>
                      <input
                        type="text"
                        required
                        name="first_name"
                        id="first_name"
                        className="form-control"
                        value={contact.first_name}
                        onChange={e => handleContactInputChange(e)}
                      />
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="form-group">
                      <label forHtml="last_name" className="control-label">Last Name *</label>
                      <input
                        type="text"
                        required
                        name="last_name"
                        id="last_name"
                        className="form-control"
                        value={contact.last_name}
                        onChange={e => handleContactInputChange(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="form-group">
                      <label forHtml="title" className="control-label">Title *</label>
                      <input
                        type="text"
                        required
                        name="title"
                        id="title"
                        className="form-control"
                        value={contact.title}
                        onChange={e => handleContactInputChange(e)}
                      />
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="form-group">
                      <label forHtml="email" className="control-label">Email Address *</label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="form-control"
                        value={contact.email}
                        required
                        onChange={e => handleContactInputChange(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <div className="form-group">
                      <label forHtml="telephone" className="control-label">Telephone Number *</label>
                      <input
                        type="tel"
                        name="telephone"
                        id="telephone"
                        className="form-control"
                        required
                        value={contact.telephone} onChange={e => handleContactInputChange(e)}
                      />
                    </div>
                  </Col>
                  <Col md={4} xs={6}>
                    <div className="form-group">
                      <label forHtml="telephone_ext" className="control-label">Telephone Ext</label>
                      <input
                        type="text"
                        name="telephone_ext"
                        id="telephone_ext"
                        className="form-control"
                        value={contact.telephone_ext}
                        onChange={e => handleContactInputChange(e)}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <div className="text-end">
              <Button
                role="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <>
      {renderButton()}
      {renderModal()}
    </>
  )
}

// CompanyContactModal.propTypes = {
//   id: PropTypes.string.isRequired,
//   buttonText: PropTypes.string.isRequired,
//   buttonTitle: PropTypes.string.isRequired,
//   showCompanyForm: PropTypes.bool.isRequired,
//   showContactForm: PropTypes.bool.isRequired,
//   update: PropTypes.func.isRequired,
//   companyId: PropTypes.string,
// }

export default CompanyContactModal;
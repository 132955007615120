import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from 'reactstrap';
import { DropdownList } from 'react-widgets';

// components
import CardWrapper from '../CardWrapper';
import Error from '../Error';

// list
import { addressTypeList, countryList, stateProvinceList } from '../../libs/list';

// api
import { saveAddress } from '../../api/company';

// css
import styles from './AddressSelector.module.scss'

const AddressSelector = ({ title, addresses, companyId, updateAddress, extraData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState({
    name: '',
    address_type: '',
    street_address: '',
    street_address_2: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    telephone: '',
  });
  const [errors, setErrors] = useState([])

  const reset = () => {
    setAddress({
      name: '',
      address_type: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      telephone: '',
    })
    console.log("extraData - address 1")
    console.log(extraData)
    if (extraData) {
      console.log("extraData - address 2")
      setAddress({
        name: '',
        address_type: '',
        street_address: extraData.street_address,
        street_address_2: '',
        city: extraData.city,
        state: extraData.state,
        zip_code: extraData.zip_code,
        country: extraData.country,
        telephone: extraData.phone_number,
      })
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen);
    reset()
  }

  const handleChange = (e) => {
    updateAddress(e, false)
    setIsOpen(false);
    reset()
  }

  const validateAddress = () => {
    setErrors([])
    let list = [];
    if  (address.name === '') {
      list.push('Name is required')
    }
    if  (address.street_address === '') {
      list.push('Street Address is required')
    }
    if  (address.city === '') {
      list.push('City is required')
    }
    if  (address.state === '') {
      list.push('State is required')
    }
    if  (address.zip_code === '') {
      list.push('Zip Code is required')
    }
    
    if (list.length  > 0) {
      setErrors(list)
      return false
    }
    return true
  }

  const createAddress = () => {
    // save the address to the company
    if (validateAddress()) {

      const data = {
        name: address.name,
        address_type: address.address_type,
        street_address: address.street_address,
        street_address_2: address.street_address_2,
        city: address.city,
        state: address.state.abbreviation,
        zip_code: address.zip_code,
        country: address.country.abbreviation,
        telephone: address.telephone,
      }

      saveAddress({ companyId, data })
      .then(res => {
        updateAddress(res.address, true)
        setIsOpen(false);
        reset()
      })
    }
  }

  const updateAddressType = (value) => {
    setAddress({
      ...address,
      address_type: value.value
    })
  }

  const handleFieldUpdate = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    })
  }

  const handleCountryUpdate = (item) => {
    setAddress({
      ...address,
      country: item.value
    })
  }

  const handleStateProvinceUpdate = (item) => {
    const country = countryList().filter(x => x.name === item.country)[0]
    setAddress({
      ...address,
      state: item, country: country
    })
  }

  return  (
    <>
      <div className={styles.button}>
        <Button
          color="primary"
          onClick={() => toggle()}
          >
            Select Address
        </Button>
      </div>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader
          toggle={toggle}
        >
          Select {title}
        </ModalHeader>
        <ModalBody>
          <h5>Select Existing Address</h5>
          <DropdownList
            data={addresses}
            textField={item => `${item.name} - ${item.street_address} ${item.city}, ${item.state} ${item.zip_code}`}
            valueField={'id'}
            onChange={e => handleChange(e)}
            placeholder='Select Address'
          />

          <div className={styles.newAddress}>
            <div className={styles.or}>or</div>
            <br />
            <h5>Create New Address</h5>
            <Error errors={errors} />
            <div className="form-group">
              <DropdownList
                data={addressTypeList()}
                textField={'name'}
                valueField={'value'}
                placeholder='Address Type'
                value={address.address_type}
                onChange={value => updateAddressType(value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                maxLength={200}
                className="form-control"
                placeholder='Name *'
                onChange={(e) => handleFieldUpdate(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="attn"
                maxLength={200}
                className="form-control"
                placeholder='Attention (C/O)'
                onChange={(e) => handleFieldUpdate(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="street_address"
                maxLength={200}
                className="form-control"
                placeholder='Street Address *'
                onChange={(e) => handleFieldUpdate(e)}
                value={address.street_address}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="street_address_2"
                maxLength={200}
                className="form-control"
                placeholder='Street Address (cont.)'
                onChange={(e) => handleFieldUpdate(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="city"
                maxLength={200}
                className="form-control"
                placeholder='City *'
                onChange={(e) => handleFieldUpdate(e)}
                value={address.city}
              />
            </div>
            <Row>
              <Col xs={12} md={4}>
                <div className="form-group">
                  <DropdownList
                    data={stateProvinceList()}
                    textField={'name'}
                    valueField={'abbreviation'}
                    placeholder='State or Province'
                    value={address.state}
                    onChange={(val) => handleStateProvinceUpdate(val)}
                    groupBy={state => state.country}
                  />
                  {/* <input
                    type="text"
                    name="state"
                    maxLength={2}
                    className="form-control"
                    placeholder='State *'
                    onChange={(e) => handleFieldUpdate(e)}
                  /> */}
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="form-group">
                  <input
                    type="text"
                    name="zip_code"
                    maxLength={10}
                    className="form-control"
                    placeholder='Zip Code *'
                    onChange={(e) => handleFieldUpdate(e)}
                    value={address.zip_code}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <DropdownList
                name="country"
                data={countryList()}
                textField="name"
                valueField="abbreviation"
                placeholder='Country'
                value={address.country}
                onChange={(val) => handleCountryUpdate(val)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="telephone"
                maxLength={200}
                className="form-control"
                placeholder='Telephone'
                onChange={(e) => handleFieldUpdate(e)}
                value={address.telephone}
              />
            </div>

            <div className={styles.buttonCenter}>
              <Button
                color="primary"
                onClick={() => createAddress()}
              >
                Add Address
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

AddressSelector.propTypes = {
  title: PropTypes.string.isRequired,
  addresses: PropTypes.array.isRequired,
  companyId: PropTypes.string,
  updateAddress: PropTypes.func.isRequired,
}

export default AddressSelector;
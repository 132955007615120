// add/edit quote
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Alert,
} from 'reactstrap'
import {
  DropdownList,
  Multiselect
} from 'react-widgets'

import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// icons
import PlusIcon from 'mdi-react/PlusIcon'
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon'

// components
import PageHeader from '../../components/PageHeader'
import PageSubHeader from '../../components/PageSubHeader'
import CardWrapper from '../../components/CardWrapper'
import AlertBlock from '../../components/AlertBlock'
import Error from '../../components/Error'

// quote components
import LineItems from '../../components/quote/LineItems'
import QuoteReview from '../../components/quote/QuoteReview'
import Notice from '../../components/quote/Notice'
import AddressSelector from '../../components/quote/AddressSelector'
import Address from '../../components/quote/Address'
import Shipping from '../../components/quote/Shipping'
import Tax  from '../../components/quote/Tax'
import CompanyContactModal from '../../components/modals/CompanyContactModal'
import Contacts from '../../components/modals/Contacts'

// utils
import { paymentOptionsList, numberToCurrency, willCallAddress } from '../../libs/utils'
import { validateQuote } from '../../libs/quote'

// styles
import styles from './Quote.module.scss'

// api
import { getCompany } from '../../api/company'
// import { getItems } from '../../api/item'
import { fullUserListByRole  } from '../../api/user'
import { listCompaniesForDropDown } from '../../api/company';
// import { getQuote } from '../../api/quote';

import { editor_toolbar, decode_message_object, encode_message_object } from '../../libs/editor';

const Quote = () => {
  const { id } = useParams();
  const pageTitle = 'Create'
  const taxRef = useRef()

  // state
  const [quote, setQuote] = useState({
    version: 2,
    company: null,
    contact: null,
    status: 'draft',
    salesRep: null,
    segment: null,
    createdBy: null,
    linesItems: [],
    billingAddress: {},
    shippingAddress: {},
    shippingTotal: 0,
    freeShipping: false,
    willCall: false,
    taxTotal: 0,
    taxRate: 0,
    taxRegion: '',
    grandTotal: 0,
    total: 0,
    doCalculate: 0,
    taxCalculated: false,
    notes: encode_message_object(''),
    internalNotes: '',
    projectName: '',
    quote_no_cc_fee: false,
    company_no_cc_fee: false,
  })
  const [steps, setSteps] = useState({
    company: true,
    contact: false,
    salesRep: false,
    segment: false,
    lineItems: false,
    payment: false,
  })
  const [company, setCompany] = useState(null)
  const [addresses, setAddresses] = useState([])
  const [contacts, setContacts] = useState([])
  const [contact, setContact] = useState(null)
  const [quoteContacts, setQuoteContacts] = useState([])
  const [salesReps, setSalesReps] = useState([])
  const [salesRep, setSalesRep] = useState(null)
  const [lineItems, setLineItems] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [message, setMessage] = useState("")

  const [companies, setCompanies] = useState([])
  const [relatedCompany, setRelatedCompany] = useState(null)

  const [clearLineItems, setClearLineItems] = useState(false)
  const [forceUpdateOfLineItems, setForceUpdateOfLineItems] = useState(false)
  const [quoteNoCcFieldDisabled, setQuoteNoCcFieldDisabled] = useState(false)
  const [companyNoCcFieldDisabled, setCompanyNoCcFieldDisabled] = useState(false)
  
  // as all items been selected?
  // const [valid, setValid] = useState(false)
  const [errors, setErrors] = useState([])

  const [isQuoteReviewOpen, setIsQuoteReviewOpen] = useState(false)

  const [c, setC] = useState(0)

  useEffect(() => {
    listCompaniesForDropDown()
      .then(data => {
        setCompanies(data.companies)
      })
    fullUserListByRole('sales_rep')
      .then(d => {
        setSalesReps(d)
      })

    console.log('id', id)
  }, [id])

  useEffect(() => {
    console.log('calculating totals')
    calculateTotals()
  }, [quote.doCalculate, lineItems, quote.shippingTotal, quote.taxTotal])

  // actions
  const handleCompanySelected = (data) => {
    getCompany(data.id)
      .then(d => {
        setContact(null)
        setContacts(d.contacts)
        setAddresses(d.addresses)
        setCompany(d)
        setSteps({ ...steps, contact: true, lineItems: false, salesRep: false, segment: false, payment: false })
        setSalesRep(d.sales_rep)
        setClearLineItems(true)
        setLineItems([])
        setQuoteContacts([])
        setQuote({
          version: 2,
          company: null,
          contact: null,
          status: 'draft',
          salesRep: null,
          segment: null,
          createdBy: null,
          linesItems: [],
          billingAddress: {},
          shippingAddress: {},
          shippingTotal: 0,
          willCall: false,
          freeShipping: false,
          taxTotal: 0,
          taxRate: 0,
          taxRegion: '',
          grandTotal: 0,
          total: 0,
          taxCalculated: false,
          notes: '',
          internalNotes: '',
          doCalculate: 0,
          projectName: '',
          quote_no_cc_fee: d.quotes_no_cc_fee,
          company_no_cc_fee: d.quotes_no_cc_fee,
        })
        setQuoteNoCcFieldDisabled(d.quotes_no_cc_fee)
        setCompanyNoCcFieldDisabled(d.quotes_no_cc_fee)
        setC(c + 1)
        console.log('d', 'updated company...')
      })
  }
    
  const setNewCompany = (data) => {
    setCompany(data)
    setSteps({ ...steps, contact: true })    
  }

  const loadCompany = (data) => {
    getCompany(data.id)
      .then(d => {
        setContacts(d.contacts)
        setAddresses(d.addresses)
        setCompany(d)
        setClearLineItems(true)
      })
  }

  const refreshCompanyList = (id) => {
    listCompaniesForDropDown()
      .then(data => {
        setCompanies(data.companies)
      })
  }

  // const handleContactSelected = (data) => {
  //   setContact(data)
    
  //   if (company.sales_rep !== null) {
  //     setSteps({...steps, lineItems: true, salesRep: true, segment: true, payment: true})
  //   } else {
  //     setSteps({...steps, salesRep: true})
  //   }
  // }

  const updateContactList = (data) => {
    console.log('updateContactList', data)
    setQuoteContacts(data.sort((a, b) => a.permission.name.localeCompare(b.permission.name)))
    if (data.length > 0) {
      if (company.sales_rep !== null) {
        setSteps({...steps, lineItems: true, salesRep: true, segment: true, payment: true})
      } else {
        setSteps({...steps, salesRep: true})
      }
    }
  }

  const updateCompanyContactList = () => {
    getCompany(company.id)
      .then(d => {
        setContacts(d.contacts)
      })
  }
  
  // const setNewContact = (data) => {
  //   loadCompany(company)
  //   setContact(data)
  //   setSteps({...steps, salesRep: true})
  // }

  const handleSalesRepSelected = (data) => {
    setSalesRep(data)
    setSteps({ ...steps, lineItems: true, salesRep: true, payment: true })
  }

  const handleRelatedCompanySelected = (data) => {
    setRelatedCompany(data)
  }

  const updateLineItems = (data) => {
    console.log('updating line items', data)
    setLineItems(data)
    setQuote(prevQuote => ({
      ...prevQuote,
      taxCalculated: false,
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const updatePaymentOptions = (data) => {
    setPaymentOptions(data)
  }

  const updateMessage = (value) => {
    setMessage(value)
  }

  const updateClearLineItems = (value) => {
    setClearLineItems(false)
    setC(c + 1)
    setQuote(prevQuote => ({
      ...prevQuote,
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const updateProjectName = (value) => {
    setQuote(prevQuote => ({ ...prevQuote, projectName: value }))
  }

  const handleUpdateNotes = (content, editor) => {
    setQuote({ ...quote, notes: content })
  }

  const handleUpdateInternalNotes = (value) => {
    setQuote({ ...quote, internalNotes: value })
  }

  const toggleQuoteReview = () => {
    taxRef.current.verifyTax()

    setTimeout(() => {
      const validate = validateQuote({ quote, paymentOptions, lineItemCount: lineItems.length })
      console.log('validate', validate)
      if (validate.errors.length === 0) {
        setIsQuoteReviewOpen(!isQuoteReviewOpen)
        setErrors([])
      } else {
        setErrors(validate.errors)
      }
    }, 300)
  }

  const updateBillingAddress = (address, reloadCompany) => {
    console.log('updateShippingAddress =>', reloadCompany)
    setQuote({ ...quote, billingAddress: address })
    if (reloadCompany) { loadCompany(company) }
  }
  
  const updateShippingAddress = (address, reloadCompany) => {
    console.log('updateShippingAddress =>', reloadCompany)
    setQuote({ ...quote, shippingAddress: address, taxCalculated: false })
    if (reloadCompany) { loadCompany(company) }
  }

  // const calculateTotals = (data, shippingCost, tax) => {
  const calculateTotals = () => {
    const { taxTotal, shippingTotal } = quote

    let _total = 0
    let _grand_total = 0
    if (lineItems.length > 0) {
      _total = lineItems.reduce((t, item) => t + item.total_price, 0)
    }
    _grand_total = _total + shippingTotal + taxTotal
    console.log("Total", _total)
    setQuote(prevQuote => ({ ...prevQuote, grandTotal: _grand_total, total: _total }))
    setC(c + 1)
  }

  const handleShippingTotalUpdate = ({ shipping, freeShipping }) => {
    setQuote(prevQuote => ({
      ...prevQuote,
      shippingTotal: shipping,
      freeShipping: freeShipping,
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const handleTaxUpdate = ({ total_tax, tax_region, tax_rate, action }) => {
    console.log("handleTaxUpdate", action)
    if (action === "tax") {
      setQuote(prevQuote => ({
        ...prevQuote,
        taxTotal: total_tax,
        taxRegion: tax_region,
        taxRate: tax_rate,
        taxCalculated: true,
        doCalculate: prevQuote.doCalculate + 1
      }))
    } else if (action === "company") {
      getCompany(company.id)
        .then(d => {
          setCompany(d)
        })
    }
  }

  const handleWillCallToggle = () => {
    const { willCall, shippingTotal } = quote
    let newShippingTotal = 0
    let newWillCall = !willCall

    setQuote(prevQuote => ({
      ...prevQuote,
      willCall: newWillCall,
      shippingTotal: newWillCall ? newShippingTotal : shippingTotal,
      shippingAddress: newWillCall ? willCallAddress() : {},
      doCalculate: prevQuote.doCalculate + 1
    }))
  }

  const handleCreditCardFee = (value, field) => {
    if (field === 'company_no_cc_fee') {
      if (!quote.company_no_cc_fee) {
        setQuote(prevQuote => ({
          ...prevQuote,
          quote_no_cc_fee: true,
          doCalculate: prevQuote.doCalculate + 1
        }))
        setQuoteNoCcFieldDisabled(true)
      } else {
        setQuoteNoCcFieldDisabled(false)
      }
    }

    setQuote(prevQuote => ({
      ...prevQuote,
      [field]: !quote[field],
      doCalculate: prevQuote.doCalculate + 1
    }))    
  }

  // renders
  const renderCompanyBlock = () => {
    return (
      <CardWrapper
        key="companyWrapper"
        id="companyWrapper"
        header="Select Company"
      >
        <Row>
          <Col md={10}>
            <DropdownList
              placeholder='Company'
              data={companies}
              textField='name'
              valueField='id'
              value={company}
              onChange={value => handleCompanySelected(value)}
            />
          </Col>
          <Col md={2} className="text-end">
            <CompanyContactModal
              buttonText={<><PlusIcon size={16} />{' '}Add</>}
              title="Add Company"
              showCompanyForm={true}
              showContactForm={false}
              id="addCompanyModalForm"
              update={(d) => setNewCompany(d)}
            />            
          </Col>
        </Row>
        <hr />
        {renderCompanyInfo()}
        {renderRelatedCompanyBlock()}
      </CardWrapper>
    )
  }

  const renderCompanyInfo = () => {
    if (company === null) { return null }
    return (
      <>
        <p>
          <strong>{company.name}</strong>
          {(company.as400_id !== '' && company.as400_id !== null) && ` (${company.as400_id})` }
          <br />
          {company.address}
          <br />
          {company.city},
          {' '}
          {company.state}
          {' '}
          {company.zip_code}
          <br />
          {company.telephone}
        </p>
      </>
    )
  }

  const renderContactBlock = () => {
    return (
      <CardWrapper
        key="contactWrapper"
        id="contactWrapper"
        header="Select Contacts"
        className={renderClassForSection('contact')}
      >
        {company !== null && (
          <Row>
            <Col>
              <Contacts
                contacts={contacts}
                update={updateContactList}
                quoteContacts={quoteContacts}
                companyId={company.id}
                updateCompanyContactList={updateCompanyContactList}
              />
            </Col>
            {/* <Col lg={10} md={8}>
              <DropdownList
                  data={contacts}
                  value={contact}
                  onChange={value => handleContactSelected(value)}
                  textField={item => `${item.first_name} ${item.last_name} [${item.title}]`}
                  valueField="id"
                  placeholder="Select Contact"
                />
            </Col>
            <Col lg={2} md={4} className="text-end">
              <CompanyContactModal
                buttonText={<><PlusIcon size={16} />{' '}Add</>}
                title="Add Contact"
                showCompanyForm={false}
                showContactForm={true}
                companyId={company !== null ? company.id : null}
                id="addContactModalForm"
                update={(d) => setNewContact(d)}
              />
            </Col> */}
          </Row>
        )}
        <hr />
        {renderContactInfo()}
      </CardWrapper>
    )
  }

  const renderContactInfo = () => {
    if (!steps.contact || quoteContacts === null || quoteContacts.length === 0) { return null }

    console.log("quoteContacts - render", quoteContacts)

    return quoteContacts.map((contact) => (
      <Row key={contact.contactId} className="border-bottom py-2">
        <Col md={4}>
          <strong>
            {contact.name}
          </strong>
        </Col>
        <Col md={6} className="small">
          {contact.email}
        </Col>
        <Col md={2}>
          <span className={`badge bg-${contact.permission.value === 'view_only' ? 'secondary' : 'primary'}`}>
            {contact.permission.name}
          </span>
        </Col>
      </Row>
    ))
  }

  const renderProjectInfo = () => {
    return (
      <CardWrapper
        key="projectWrapper"
        id="projectWrapper"
        header="Project Information"
        className={renderClassForSection('salesRep')}
      >
        <Row>
          <Col>
            {company !== null && (
              <FormGroup floating>
                <Input
                  name="projectName"
                  id="projectName"
                  type="text"
                  className="form-control"
                  placeholder="Project Name"
                  value={quote.projectName}
                  onChange={e => updateProjectName(e.target.value)}
                />
                <Label>Project Name</Label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </CardWrapper>
    )
  }

  const renderSalesRepBlock = () => {
    return (
      <CardWrapper
        key="salesRepWrapper"
        id="salesRepWrapper"
        header="Select Account Executive"
        className={renderClassForSection('salesRep')}
      >
        {company !== null && (
          <DropdownList
            textField="name"
            valueField="id"
            data={salesReps}
            value={salesRep}
            onChange={value => handleSalesRepSelected(value)}
            disabled={company.sales_rep !== null}
          />
        )}
        <hr />  
        {renderSalesRep()}
      </CardWrapper>
    )
  }

  const renderSalesRep = () => {
    if (company === null) { return null }
    if (salesRep !== null) {
      return (
        <p>
          <strong>{salesRep.name}</strong>
          <br />
          {salesRep.email}
        </p>
      )
    }
  }

  // related company for those w/o AS400 ID value (Cash Accounts mainly)
  const renderRelatedCompanyBlock = () => {
    if (company === null) { return null }
    if (company.as400_id !== null || company.as400_id === '-1') { return null }
    if (company.as400_id > 0) { return null }
    return (
      <>
        <hr />
        <h5 className={styles.cashAccountHeader}>
          Company associated with a <span>CASH ACCOUNT</span>?
        </h5>
        <DropdownList
          placeholder='Company'
          data={companies.filter(c => (c.as400_id !== null && c.as400_id !== 0 && c.as400_id !== -1))}
          textField='name'
          valueField='id'
          value={relatedCompany}
          onChange={value => handleRelatedCompanySelected(value)}
        />
        
      </>
    )
  }

  const renderClassForSection = (section) => {
    if (steps[section]) {
      return styles.active
    }
    return styles.inactive
  }

  // shipping - set the price for this
  const renderShipping = () => {
    if (company === null) { return null }
    return (
      <Shipping
        shippingCost={quote.shippingTotal}
        updateData={(data) => handleShippingTotalUpdate(data)}
      />
    )
  }

  const renderTax = () => {
    if (company === null) { return null }
    return (
      <Tax
        quote={quote}
        company={company}
        total={0}
        updateData={(data) => handleTaxUpdate(data)}
        ref={taxRef}
      />
    )
  }

  return (
    <>
      <PageHeader title={`Quote: ${pageTitle}`} />
      {/* <Notice />       */}
      <Row>
        <Col md={6}>
          {renderCompanyBlock()}
          {renderProjectInfo()}
        </Col>
        <Col md={6}>
          {renderContactBlock()}
          {renderSalesRepBlock()}
        </Col>
      </Row>

      {steps.company && steps.contact && steps.salesRep && (
        <Row>
          <Col md={6}>
            <CardWrapper header="Billing Address">
              <AddressSelector
                title="Billing Address"
                addresses={addresses}
                companyId={company.id}
                updateAddress={(data, reload) => updateBillingAddress(data, reload)}
              />
              <Address
                address={quote.billingAddress}
              />
            </CardWrapper>
          </Col>
          <Col md={6}>
            <CardWrapper header="Shipping Address">
              <FormGroup switch>
                <Input
                  type="switch"
                  value={quote.willCall}
                  onClick={() => handleWillCallToggle()}
                />
                {' '}
                Will Call
              </FormGroup>
              <hr />
              {!quote.willCall && (
                <AddressSelector
                  title="Shipping Address"
                  addresses={addresses}
                  companyId={company.id}
                  updateAddress={(data, reload) => updateShippingAddress(data, reload)}
                />
              )}
              <Address
                address={quote.shippingAddress}
              />
            </CardWrapper>
          </Col>
        </Row>
      )}
      
      <CardWrapper
        key="lineItemsWrapper"
        id="lineItemsWrapper"
        header="Product/Line Items"
        className={renderClassForSection('lineItems')}
      >
        {steps.contact && (
          <LineItems
            updateData={updateLineItems}
            companyId={company.id || null}
            as400Id={company.as400_id || null}
            clear={clearLineItems}
            updateClear={updateClearLineItems}
            forceUpdate={forceUpdateOfLineItems}
            quoteStatus={quote.status}
            lineItems={[]}
          />
        )}
        <Row>
          <Col xs={12}><hr /></Col>
        </Row>
        {renderTax()}
        {renderShipping()}
        <Row className="mt-2">
          <Col xs={12} md={5}>&nbsp;</Col>
          <Col xs={12} md={2}>&nbsp;</Col>
          <Col xs={12} md={2} className="text-end">Grand Total:</Col>
          <Col xs={12} md={2} className="text-end">
            {numberToCurrency(quote.grandTotal)}
          </Col>
        </Row>
      </CardWrapper>

      <Row>
        <Col md={4}>
          <CardWrapper
            key="paymentWrapper"
            id="paymentWrapper"
            header="Payment Options"
            className={renderClassForSection('payment')}
          >
            {steps.salesRep && (
              <>
                <Multiselect
                  data={paymentOptionsList(company)}
                  dataKey="value"
                  textField="label"
                  placeholder='Select Payment Options'
                  onChange={value => updatePaymentOptions(value)}
                  value={paymentOptions}
                />
                {company !== null && (
                  <p className={styles.terms}>
                    Terms on File:
                    {' '}
                    {company.terms || 'N/A'}
                  </p>
                )}
                {salesRep !== null && salesRep.special_abilities.quotes_no_cc_fee && (
                  <>
                    <hr />
                    <h5>Credit Card Fee Options</h5>
                    <FormGroup switch>
                      <Input
                        disabled={quoteNoCcFieldDisabled}
                        type="switch"
                        checked={quote.quote_no_cc_fee}
                        onClick={e => handleCreditCardFee(e, 'quote_no_cc_fee')}
                        id="quote_no_cc_fee"
                      />
                      <Label for="quote_no_cc_fee">
                        No Credit Card Fees for this Quote
                      </Label>
                    </FormGroup>
                    <FormGroup switch>
                      <Input
                        disabled={companyNoCcFieldDisabled}
                        type="switch"
                        checked={quote.company_no_cc_fee}
                        onClick={e => handleCreditCardFee(e, 'company_no_cc_fee')}
                        id="company_no_cc_fee"
                      />
                      <Label for="company_no_cc_fee">
                        No Credit Card Fees for all Quotes for this Company
                      </Label>
                    </FormGroup>
                    {companyNoCcFieldDisabled && (
                      <Alert color="info">
                        Credit Card Fees are disabled for this company.
                      </Alert>
                    )}
                  </>
                )}
              </>
            )}
          </CardWrapper>

          <CardWrapper
            header="Internal Notes (Not Visible to Customer)"
            headerColorClass="headerGrey"
          >
            {steps.salesRep && (
              <>
                <Alert color="warning">
                  <textarea
                    className="form-control"
                    rows="10"
                    placeholder="Enter INTERNAL notes here..."
                    value={quote.internalNotes}
                    onChange={e => handleUpdateInternalNotes(e.target.value)}
                  />
                </Alert>
              </>
            )}
          </CardWrapper>
        </Col>
        <Col md={8}>
          <CardWrapper
            key="notesWrapper"
            id="notesWrapper"
            header="Notes for Customer"
          >
            {steps.salesRep && (
              <>
                <Alert color="info">
                  <Editor
                    wrapperClassName="card p-2"
                    editorState={quote.notes}
                    onEditorStateChange={(content) => handleUpdateNotes(content)}
                    toolbar={editor_toolbar()}
                  />
                </Alert>
              </>
            )}
          </CardWrapper>
        </Col>
      </Row>
      <Row>
        <Col
          md={{size: 4, offset: 8}}
          className="text-end"
        >
          {errors.length > 0 && (
            <Error
              errors={errors}
              header="Fix the following before saving:"
            />
          )}
          <br />
          <button
            className="btn btn-primary"
            onClick={e => toggleQuoteReview()}
            hidden={!steps.contact}
          >
            Save & Review Quote
          </button>
        </Col>
      </Row>
      <QuoteReview
        quote={quote}
        company={company}
        relatedCompany={relatedCompany}
        contact={null}
        contactQuotes={quoteContacts}
        lineItems={lineItems}
        salesRep={salesRep}
        message={message}
        paymentOptions={paymentOptions}
        open={isQuoteReviewOpen}
        toggle={toggleQuoteReview}
      />
    </>
  )
}

export default Quote;
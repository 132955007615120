import React from 'react';
import { Link } from 'react-router-dom';
import GaugeIcon from 'mdi-react/GaugeIcon';
import FormatListBulletedIcon from 'mdi-react/FormatListBulletedIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import SettingsIcon from 'mdi-react/SettingsIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import AddIcon from 'mdi-react/AddIcon';
import EventsIcon from 'mdi-react/EventsIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon'
import CheckBoxIcon from 'mdi-react/CheckBoxIcon';
import HammerIcon from 'mdi-react/HammerIcon';
import MapIcon from 'mdi-react/MapIcon';
import ClockIcon from 'mdi-react/ClockIcon';
import GlobeIcon from 'mdi-react/GlobeIcon';

import packageData from '../../package.json'

import { checkForAbility } from '../libs/utils';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: '',
      version: `Version ${packageData.version} - ${packageData.icon}`,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    console.log(location);
    if (location) {
      const { pathname } = location;
      this.setState({ expand: pathname.split('/')[1] });
    }
  }

  activeThis = section => {
    let classes = '';
    if (section === this.state.expand) {
        classes += "active";
    }
    return classes;
  }

  expandThis = section => {
    let classes = 'collapse';
    if (section === this.state.expand) {
        classes += " in";
    }
    return classes;
  }

  updateSection = (e, section) => {
    // e.preventDefault();
    const { expand } = this.state;
    if (expand === section) {
      this.setState({ expand: '' });
    } else {
      this.setState({ expand: section });
    }
  }

  render() {
    const { expand, version } = this.state;
    const { user } = this.props;

    return (

      <nav className="sidebar-nav active">
        <ul id="sidebarnav" className="in">
          <li className={this.activeThis('dashboard')}>
            <Link to="/dashboard" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'dashboard')}>
              <GaugeIcon size={21} />
              <span className="hide-menu">
                Dashboard
              </span>
            </Link>
          </li>
          <li className={this.activeThis('quote')}>
            <Link to="/quote" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'quote')}>
              <HammerIcon size={21} />
              <span className="hide-menu">
                Quotes
              </span>
            </Link>
          </li>
          {checkForAbility('check', localStorage) && (
            <li className={this.activeThis('pending')}>
              <Link to="/quote/pending" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'pending')}>
                <ClockIcon size={21} />
                <span className="hide-menu">
                  Pending Quotes
                </span>
              </Link>
            </li>
          )}
          <li className={this.activeThis('call-log')}>
            <Link to="/call-log" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'call-log')}>
              <FormatListBulletedIcon size={21} />
              <span className="hide-menu">
                Call Log
              </span>
            </Link>
          </li>
          <li className={this.activeThis('tasks')}>
            <a href="#" className="waves-effect waves-dark has-arrow" aria-expanded={false} onClick={(e) => this.updateSection(e, 'tasks')}>
              <CheckBoxIcon size={21} />
              <span className="hide-menu">
                Task
              </span>
            </a>
            <ul className={this.expandThis('tasks')}>
              <li>
                <Link to="/tasks"><CheckBoxIcon size={16} />View Tasks</Link>
                <Link to="/tasks/new"><AddIcon size={16} />Add Task</Link>
              </li>
            </ul>
          </li>
          <li className={this.activeThis('companies')}>
            <a href="#" className="waves-effect waves-dark has-arrow" aria-expanded={false} onClick={(e) => this.updateSection(e, 'companies')}>
              <DomainIcon size={21} />
              <span className="hide-menu">
                Companies
              </span>
            </a>
            <ul className={this.expandThis('companies')}>
              <li>
                <Link to="/companies"><DomainIcon size={16} />View Companies</Link>
                <Link to="/companies/new"><AddIcon size={16} />Add Company</Link>
                <Link to="/companies/prospects"><DomainIcon size={16} />View Propsects</Link>
              </li>
            </ul>
          </li>
          <li className={this.activeThis('calendar')}>
            <a href="#" className="waves-effect waves-dark has-arrow" aria-expanded={false} onClick={(e) => this.updateSection(e, 'calendar')}>
              <CalendarIcon size={21} />
              <span className="hide-menu">
                Calendar
              </span>
            </a>
            <ul className={this.expandThis('calendar')}>
              <li><Link to="/calendar"><CalendarIcon size={16} />Calendar</Link></li>
              <li><Link to="/events"><EventsIcon size={16} />Events</Link></li>
            </ul>
            
          </li>
          <li className={this.activeThis('report')}>
            <Link to="/reports" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'report')}>
              <ChartBarIcon size={21} />
              <span className="hide-menu">
                Reporting
                </span>
            </Link>
          </li>

          {checkForAbility('ecommerce', localStorage) && (
            <li className={this.activeThis('map')}>
              <Link to="/map" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'map')}>
                <MapIcon size={21} />
                <span className="hide-menu">
                  Map
                </span>
              </Link>
            </li>
          )}

          {checkForAbility('website_feed', localStorage) && (
            <li className={this.activeThis('website')}>
              <Link to="/website" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'website')}>
                <GlobeIcon size={21} />
                <span className="hide-menu">
                  Website Feed
                </span>
              </Link>
            </li>
          )}

          {user && ['admin', 'sales_manager', 'csr_manager', 'sales_admin'].indexOf(user.role) > -1 && (
            <li className={this.activeThis('admin')}>
              <Link to="/admin" className="waves-effect waves-dark" onClick={(e) => this.updateSection(e, 'admin')}>
                <SettingsIcon size={21} />
                <span className="hide-menu">
                  Admin
                </span>
              </Link>
            </li>
          )}

          <li className="text-center">
            <small>{version}</small>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;

import api from './init';

export function signIn(data) {
  return api.post('/api/v1/login', data)
    .then((res) => {
      return res.data;
    });
    // .catch((error) => {
    //   if (/ 401/.test(error.message)) {
    //     console.log(error.message);
    //     error = new Error('The email/password combination was incorrect');
    //   }
    //   if (/ 422/.test(error.message)) {
    //     // console.log(error.message);
    //     error = new Error('The email/password combination was incorrect!');
    //   }
    //   throw error;
    // });
}

export function recoverPassword(data) {
  return api.post('/api/v1/forgot', data)
    .then((res) => {
      return res.data;
    });
}

export function resetPassword(data) {
  return api.put('/api/v1/forgot', data)
    .then((res) => {
      return res.data;
    });
}

export function signOut() {
  sessionStorage.removeItem('jwt');
  window.location = '/login';
  // return api.delete('/api/v1/logout')
  //   .then((res) => {
  //   });
}

import api from './init';
import returnError from './returnError';

export function getWebsiteList(filters) {
  const url = `/api/v1/websites.json?page=${filters.currentPage}&status=${filters.status}`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      returnError(error);
    });
}


export function getWebsite(id) {
  const url = `/api/v1/websites/${id}.json`;
  return api
    .get(url, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      returnError(error);
    });
}

export function updateWebsite(id, data) {
  const url = `/api/v1/websites/${id}.json`;
  return api
    .put(url, data, { withCredentials: true })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      returnError(error);
    });
}

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import Moment from 'react-moment';

// components
import PageHeader from '../../components/PageHeader';
import CardWrapper from '../../components/CardWrapper';

import CompanyContactModal from '../../components/modals/CompanyContactModal'
import Address from '../../components/quote/Address';
import AddressSelector from '../../components/quote/AddressSelector'

// icons
import { PlusIcon } from 'mdi-react/PlusIcon';

// libs

import { getWebsite, updateWebsite } from '../../api/website'
import { listCompaniesForDropDown, getCompany } from '../../api/company'
import { fullUserListByRole  } from '../../api/user'




const WebsiteQuote = () => {
  const { id } = useParams()

  const [website, setWebsite] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [salesReps, setSalesReps] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);


  const [repFromCompany, setRepFromCompany] = useState(false);
  

  const [quoteItems, setQuoteItems] = useState([]);


  useEffect(() => {
    getWebsite(id).then((res) => {
      setWebsite(res);
    });

    listCompaniesForDropDown().then((res) => {
      setCompanies(res.companies);
    });

    fullUserListByRole('sales_rep')
          .then(dd => {
            setSalesReps(dd)
          })
  }, [id]);

  if (!website) {
    return <div>Loading...</div>;
  } else {
    if (website.source === "comment") {
      window.location.href = "/website";
    }
  }

  //  actions

  const changeSelectedCompany = (value) => {
    setContacts([]);
    setSelectedContact(null);
    setSelectedCompany(value);

    getCompany(value.id)
      .then(d => {
        setContacts(d.contacts)
        setAddresses(d.addresses)
        if (d.sales_rep) {
          setSelectedSalesRep(d.sales_rep);
          setRepFromCompany(true);
        } else {
          setSelectedSalesRep(null);
          setRepFromCompany(false);
        }
      })
  }

  const changeSelectedContact = (value) => {
    setSelectedContact(value);
  }

  const changeSelectedSalesRep = (value) => {
    setSelectedSalesRep(value);
  }

  const changeSelectedBillingAddress = (value) => {
    setSelectedBillingAddress(value);
  }

  const changeSelectedShippingAddress = (value) => {  
    setSelectedShippingAddress(value);
  }

  const addItemToQuote = (item) => {
    console.log('item', item);
    setQuoteItems([])
    let list = []

    document.querySelectorAll('.quote-item-checkbox').forEach((checkbox, index) => {
      if (checkbox.checked) {
        list.push(website.data.quote_items[index]);
      }
    });

    setQuoteItems(list);    
  }

  const refreshCompanyList = (id) => {
    listCompaniesForDropDown()
      .then(data => {
        setCompanies(data.companies)
      })
  }

  
  const setNewCompany = (data) => {
    refreshCompanyList(null)
    setContacts([])
    setSelectedContact(null)
    setSelectedSalesRep(null)
    setAddresses([])
    setSelectedBillingAddress(null)
    setSelectedShippingAddress(null)
    setSelectedCompany(data)
  }

  const setNewContact = (data) => {
    getCompany(selectedCompany.id)
      .then(d => {
        setContacts(d.contacts)
        setSelectedContact(data)
      })
  }

  const updateBillingAddress = (data, reload) => {
    setSelectedBillingAddress(data);
    if (reload) {
      getCompany(selectedCompany.id)
        .then(d => {
          setAddresses(d.addresses)
        })
      }
    }
    
    const updateShippingAddress = (data, reload) => {
      setSelectedShippingAddress(data);
      if (reload) {
        getCompany(selectedCompany.id)
          .then(d => {
            setAddresses(d.addresses)
          })
      }
  }


  const handleSubmit = () => {
    console.log("submit");

    let data = {
      company_id: selectedCompany.id,
      contact_id: selectedContact.id,
      sales_rep_id: selectedSalesRep.id,
      billing_address_id: selectedBillingAddress.id,
      shipping_address_id: selectedShippingAddress.id,
      quote_items: quoteItems,
      contact_quotes: [
        {
          id: null,
          contact_id: selectedContact.id,
          permission: "approval_ability"
        }
      ],
      source: "quote"
    }

    updateWebsite(website.id, data).then((res) => {
      console.log('res', res);
      window.location.href = `/quote/${res.quote_id}`;
    })    
  }

  const canCreate = () => {
    if (selectedCompany && 
      selectedContact && 
      selectedSalesRep && 
      selectedBillingAddress && 
      selectedShippingAddress) {
      return true;
    }
    return false;
  }

  // render

  return (
    <>
      <PageHeader title="Website Quote Request to Quote" />
      <Row>
        <Col md={4}>
          <CardWrapper>
            <h3>Information</h3>
            <Row>
              <Col md={6}>Name</Col>
              <Col md={6}>
                {website.data.first_name} {website.data.last_name}
              </Col>
            </Row>
            <Row>
              <Col md={6}>Company</Col>
              <Col md={6}>{website.data.company}</Col>
            </Row>
            <Row>
              <Col md={6}>Email</Col>
              <Col md={6}>{website.data.email_address}</Col>
            </Row>
            <Row>
              <Col md={6}>Phone</Col>
              <Col md={6}>{website.data.phone_number}</Col>
            </Row>
            <h3>&nbsp;</h3>
            <Row>
              <Col md={6}>Address</Col>
              <Col md={6}>
                {website.data.street_address}
                <br />
                {website.data.city}, {website.data.state} {website.data.zip_code} {website.data.country}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h3>Requested Items</h3>
              </Col>
            </Row>    
    
            {website.data.quote_items && (
              <>
                <Row>
                  <Col md={4}><strong>SKU</strong></Col>
                  <Col md={4}><strong>Name</strong></Col>
                  <Col md={4}><strong>Quantity</strong></Col>
                </Row>
                <hr />
                {website.data.quote_items.map((item) => (
                  <>
                    <Row key={item.id}>
                      <Col md={4}>{item.sku}</Col>
                      <Col md={4}>{item.name}</Col>
                      <Col md={4}>{item.quantity}</Col>
                    </Row>
                    <hr />
                  </>
                ))}
              </>
            )}
          </CardWrapper>
        </Col>
        <Col md={8}>
          <CardWrapper>
            <Alert color="warning">
              <h2>Build the Quote</h2>
              <p>Some data will be pre-filled based on the information provided if new company, contact or address are required.</p>
              <p>Pricing to be adjusted after the quote is created (You will be redirected to the QUOTE EDIT screen after).</p>
            </Alert>
            <Row>
              <Col md={6}>
                <h5>Companies</h5>
                {companies.length > 0 ? (
                  <>
                    <DropdownList
                      textField="name"
                      valueField="id"
                      data={companies}
                      value={selectedCompany}
                      onChange={changeSelectedCompany}
                    />
                    <div class="text-end">
                      <CompanyContactModal
                        buttonText="+ Add"
                        title="Add Company"
                        showCompanyForm={true}
                        showContactForm={false}
                        id="addCompanyModalForm"
                        update={(d) => setNewCompany(d)}
                        extraData={website.data}
                      />            
                    </div>
                  </>
                ) : (
                  <em>Loading companies found.</em>
                )}
              </Col>
            
              <Col md={6}>
                <h5>Contact</h5>
                {selectedCompany ? (
                  <>
                    <DropdownList
                      textField={item => `${item.first_name} ${item.last_name} [${item.title}]`}
                      valueField="id"
                      data={contacts}
                      value={selectedContact}
                      onChange={changeSelectedContact}
                    />
                    <div class="text-end">
                      <CompanyContactModal
                        buttonText="+ Add"
                        title="Add Contact"
                        showCompanyForm={false}
                        showContactForm={true}
                        id="addContactModalForm"
                        companyId={selectedCompany !== null ? selectedCompany.id : null}
                        update={(d) => setNewContact(d)}
                        extraData={website.data}
                      />            
                    </div>
                  </>
                ) : (
                  <em>Select Company...</em>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <h5>Sales Rep</h5>
                {selectedCompany ? (                  
                  <>
                    {!repFromCompany ? (
                      <DropdownList
                        textField="name"
                        valueField="id"
                        data={salesReps}
                        value={selectedSalesRep}
                        onChange={changeSelectedSalesRep}
                      />
                    ) : (
                      <>
                        {selectedSalesRep?.name}
                      </>
                    )}
                  </>
                ) : (
                  <em>Select Company...</em>
                )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <h5>Billing Address</h5>
                {selectedCompany ? (
                  <>
                    <AddressSelector
                      title="Billing Address"
                      addresses={addresses}
                      companyId={selectedCompany.id}
                      updateAddress={(data, reload) => updateBillingAddress(data, reload)}
                      extraData={website.data}
                    />
                    <Address
                      address={selectedBillingAddress}
                    />
                  </>
                ) : (
                  <em>Select Company...</em>
                )}
              </Col>
              <Col md={6}>
                <h5>Shipping Address</h5>
                {selectedCompany ? (
                  <>
                    <AddressSelector
                      title="Shipping Address"
                      addresses={addresses}
                      companyId={selectedCompany.id}
                      updateAddress={(data, reload) => updateShippingAddress(data, reload)}
                      extraData={website.data}
                    />
                    <Address
                      address={selectedShippingAddress}
                    />
                  </>
                ) : (
                  <em>Select Company...</em>
                )}
              </Col>
            </Row>
            <h3 className="mt-4">Items</h3>
            <Row>
              <Col md={4}><strong>SKU</strong></Col>
              <Col md={4}><strong>Quantity</strong></Col>
              <Col md={4}><strong>Include?</strong></Col>
            </Row>
            <hr />
            <div className="quote-items-container">
              {website.data.quote_items.map((item) => (
                <Row key={item.sku} className="quote-item-row">
                  <Col md={4}>{item.sku}</Col>
                  <Col md={4}>{item.quantity}</Col>
                  <Col md={4}>
                    <input type="checkbox" className="quote-item-checkbox" onChange={() => addItemToQuote(item)} />
                  </Col>
                </Row>
              ))}
            </div>

            <Row className="mt-4">
              <Col className="text-center">
                {canCreate() ? (
                  <>
                    <button className="btn btn-primary mt-4" onClick={handleSubmit}>
                      Create Quote
                    </button>
                    <p className="mt-2">You will be able to edit the quote after it is created. The quote will be in DRAFT status.</p>
                  </>
                ) : (
                  <em>Need more information in order to create a quote.</em>
                )}
              </Col>
            </Row>
          </CardWrapper>
        </Col>
      </Row>

    </>
  )
}

export default WebsiteQuote;